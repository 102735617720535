import PinCardList from "../PinCardList/PinCardList";
import "./PinViewer.css";

function PinViewer(){
    return(
        <div className="center">
            <PinCardList/>
        </div>
    );
}

export default PinViewer;